import { AddDiscussionDto, DiscussionAndSolutionDto } from "@api";

export declare type IDiscussionInputModel =
    Partial<Omit<AddDiscussionDto, "origin">>;

export const convertToDiscussionInput = (discussion: DiscussionAndSolutionDto): IDiscussionInputModel => ({
    type: discussion.type,
    creatorUserId: discussion.creator?.userId,
    departmentId: discussion.department?.id,
    categoryId: discussion.category?.id,
    subCategoryId: discussion.category?.subCategory?.id,
    heading: discussion.heading,
    description: discussion.description,
    priority: discussion.priority,
});
