import { NotificationItemsFilter } from "@api";

export const getNotificationItemsFilterNameKey = (filter: NotificationItemsFilter): string => {
    switch (filter) {
        case NotificationItemsFilter.exceptionsOnly:
            return "setupNotificationScreen.exceptionsOnly";
        case NotificationItemsFilter.notUpdatedOnly:
            return "setupNotificationScreen.notUpdatedOnly";
        case NotificationItemsFilter.all:
        default:
            return "setupNotificationScreen.showAll";
    }
};

export const getNotificationItemsFilterTooltipKey = (filter: NotificationItemsFilter): string | null => {
    switch (filter) {
        case NotificationItemsFilter.exceptionsOnly:
            return "setupNotificationScreen.exceptionsOnlyTooltip";
        case NotificationItemsFilter.notUpdatedOnly:
            return "setupNotificationScreen.notUpdatedOnlyTooltip";
        case NotificationItemsFilter.all:
        default:
            return null;
    }
};
