import { GetActionDto, GetGoalDto, GetNumberDto, GetReportDto, NumberRecordDetailDto } from "@api";

import { IDiscussionInputModel } from "~shared/dialogs/discussion-dialog-shared";

import { GenericItem } from "./input-builder-core";

export const mapGenericDiscussionInput = (item: GenericItem): IDiscussionInputModel => ({
    departmentId: item.department?.id,
    categoryId: item.category?.id,
    subCategoryId: item.category?.subCategory?.id,
});

export const mapGoalDiscussionInput = (goal: GetGoalDto): IDiscussionInputModel => mapGenericDiscussionInput(goal);

export const mapNumberDiscussionInput =
    (number: GetNumberDto | NumberRecordDetailDto): IDiscussionInputModel => mapGenericDiscussionInput(number);

export const mapReportDiscussionInput = (report: GetReportDto): IDiscussionInputModel => mapGenericDiscussionInput(report);

export const mapActionDiscussionInput = (action: GetActionDto): IDiscussionInputModel => mapGenericDiscussionInput(action);
