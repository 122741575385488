import { Injectable } from "@angular/core";
import { SimpleCompanyTeamDto, TeamSettingsApi } from "@api";
import { map, Observable } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { getTeamCacheItem, ICompanyTeamCache, retryWithDelay, setTeamCacheItem } from "~shared/util/caching";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortTeam } from "~shared/util/sorters";

import { IClearableRepository } from "./repository";

@Injectable({
    providedIn: "root"
})
export class TeamSettingsRepository extends WithDestroy() implements IClearableRepository {
    private approvingTeamsCache: ICompanyTeamCache<SimpleCompanyTeamDto[]> = {};
    private delegationTeamsCache: ICompanyTeamCache<SimpleCompanyTeamDto[]> = {};

    constructor(
        private readonly teamSettingsApi: TeamSettingsApi,
    ) {
        super();
    }

    clearCache = () => {
        this.approvingTeamsCache = {};
        this.delegationTeamsCache = {};
    };

    getApprovingTeams = (companyId: string, teamId: string): Observable<SimpleCompanyTeamDto[]> => {
        let cacheItem = getTeamCacheItem(this.approvingTeamsCache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getApprovingTeamsObservable(companyId, teamId);
            setTeamCacheItem(this.approvingTeamsCache, companyId, teamId, cacheItem);
        }
        return cacheItem;
    };

    getDelegationTeams = (companyId: string, teamId: string): Observable<SimpleCompanyTeamDto[]> => {
        let cacheItem = getTeamCacheItem(this.delegationTeamsCache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getDelegationTeamsObservable(companyId, teamId);
            setTeamCacheItem(this.delegationTeamsCache, companyId, teamId, cacheItem);
        }
        return cacheItem;
    };

    private getApprovingTeamsObservable = (companyId: string, teamId: string) =>
        this.teamSettingsApi.getApprovingTeams(companyId, teamId).pipe(
            retryWithDelay(),
            map(teams => teams.sort(sortTeam.ascending())),
            shareReplayUntil(this.destroyed$),
        );

    private getDelegationTeamsObservable = (companyId: string, teamId: string) =>
        this.teamSettingsApi.getDelegationTeams(companyId, teamId).pipe(
            retryWithDelay(),
            map(teams => teams.sort(sortTeam.ascending())),
            shareReplayUntil(this.destroyed$),
        );

}
