<app-homepage-scaffold *ngIf="report">
    <span *appHomepageTitle class="multiline-description">
        {{ report.description }}
    </span>

    <ng-template appHomepageTitleActions>
        <app-schedule-navigator [item]="report" [schedule]="schedule$ | async" (selected)="selectSchedule($event)"></app-schedule-navigator>
    </ng-template>

    <ng-template appHomepageActions>
        <app-watch-item-button type="report" [source]="report"></app-watch-item-button>
        <app-add-report-child-action-button [source]="report"></app-add-report-child-action-button>
        <app-add-report-child-discussion-button [source]="report"></app-add-report-child-discussion-button>
        <ng-container *ngIf="allowEdit">
            <button mat-icon-button type="button" [matMenuTriggerFor]="popupMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #popupMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="edit()" *ngIf="(access$ | async)?.canEdit; else viewButton">
                    <mat-icon color="primary">edit</mat-icon>
                    <span>{{'Edit' | translate}}</span>
                </button>
                <ng-template #viewButton>
                    <button mat-menu-item (click)="view()">
                        <mat-icon color="primary">visibility</mat-icon>
                        <span>{{'View' | translate}}</span>
                    </button>
                </ng-template>
                <button mat-menu-item (click)="delete()" [disabled]="!(access$ | async)?.canDelete">
                    <mat-icon color="warn">delete</mat-icon>
                    <span>{{'Delete' | translate}}</span>
                </button>
            </mat-menu>
        </ng-container>
    </ng-template>
    
    <section class="wf-section">
        <dl>
            <div>
                <dt translate="reports.owner"></dt>
                <dd>
                    <app-delegated-user-display [entity]="report" primary>
                        {{ getUserName(report.owner) }}
                    </app-delegated-user-display>
                </dd>
            </div>
            <div>
                <dt translate="reports.updater"></dt>
                <dd>
                    <app-delegated-user-display [entity]="report" mode="updater" primary>
                        {{ getUserName(report.updater) }}
                    </app-delegated-user-display>
                </dd>
            </div>
            <div>
                <dt translate="reports.team"></dt>
                <dd>{{ report.team.name }} ({{ report.company.name }})</dd>
            </div>
            <div *ngIf="report.department">
                <dt translate="reports.department"></dt>
                <dd>{{ report.department.name }}</dd>
            </div>
            <div *ngIf="report.category" class="sub-list">
                <div>
                    <dt translate="reports.category"></dt>
                    <dd>{{ report.category.description }}</dd>
                </div>
                <div *ngIf="report.category.subCategory">
                    <dt translate="reports.subCategory"></dt>
                    <dd>{{ report.category.subCategory.description }}</dd>
                </div>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'homepage.details' | translate">
        <dl>
            <div>
                <dt translate="reports.planningStatus"></dt>
                <dd>
                    <wf-planning-status [status]="report.reportStatus"></wf-planning-status>
                </dd>
            </div>
            <div>
                <dt translate="reports.captureFrequency"></dt>
                <dd>{{ getUpdateScheduleDescription(report.scheduleDefinition) }}</dd>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'reports.reports' | translate">
        <ng-template wfExpandableSectionActions>
            <app-report-summary [report]="report"></app-report-summary>
        </ng-template>
        <app-report-records-table class="table-container" [records]="records$ | async" (recordUpdated)="recordUpdated($event)"
            [selectedWeek]="isRecord(report) ? report.week : null">
        </app-report-records-table>
    </section>

    <ng-container *ngIf="relatedActions$ | async; let actions">
        <section wf-expandable-section [label]="'homepage.relatedActions' | translate" *ngIf="actions.length" @fadeIn>
            <app-related-actions-table *wfExpandableSectionBody [actions]="actions"></app-related-actions-table>
        </section>
    </ng-container>

    <ng-container *ngIf="relatedDiscussions$ | async; let discussions">
        <section wf-expandable-section [label]="'homepage.relatedDiscussions' | translate" *ngIf="discussions.length" @fadeIn>
            <app-related-discussions-table *wfExpandableSectionBody [discussions]="discussions"></app-related-discussions-table>
        </section>
    </ng-container>

</app-homepage-scaffold>