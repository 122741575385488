import { EntityReferenceDto, GetActionDto, GetGoalDto, GetNewsItemDto, GetNumberDto, GetReportDto, GoalRecordDetailDto, NumberRecordDetailDto, ReportRecordDetailDto } from "@api";

import { EntityType } from "~shared/enums";

import { getDelegatedItemCompanyTeam } from "./delegation-helper";

export interface IOriginDetails extends EntityReferenceDto {
    type: EntityType;
    heading: string;
    description?: string;
}

export const mapNewsOrigin = (news: GetNewsItemDto): IOriginDetails => ({
    type: EntityType.news,
    companyId: news.company.id,
    teamId: news.team.id,
    id: news.id,
    heading: news.heading,
    description: news.description,
});

export const mapGoalOrigin = (goal: GetGoalDto | GoalRecordDetailDto): IOriginDetails => {
    const { company, team } = getDelegatedItemCompanyTeam(goal);
    return {
        type: EntityType.goal,
        companyId: company.id,
        teamId: team.id,
        id: goal.id,
        heading: goal.heading,
        description: goal.description,
        week: ("week" in goal ? goal.week : undefined),
    };
};

export const mapNumberOrigin = (number: GetNumberDto | NumberRecordDetailDto): IOriginDetails => {
    const { company, team } = getDelegatedItemCompanyTeam(number);
    return {
        type: EntityType.number,
        companyId: company.id,
        teamId: team.id,
        id: number.id,
        heading: number.description,
        week: ("week" in number ? number.week : undefined),
    };
};

export const mapReportOrigin = (report: GetReportDto | ReportRecordDetailDto): IOriginDetails => {
    const { company, team } = getDelegatedItemCompanyTeam(report);
    return ({
        type: EntityType.report,
        companyId: company.id,
        teamId: team.id,
        id: report.id,
        heading: report.description,
        week: ("week" in report ? report.week : undefined),
    });
};

export const mapActionOrigin = (action: GetActionDto): IOriginDetails => {
    const { company, team } = getDelegatedItemCompanyTeam(action);
    return {
        type: EntityType.action,
        companyId: company.id,
        teamId: team.id,
        id: action.id,
        heading: action.description,
    };
};
