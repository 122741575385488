<button type="button" class="user-menu-button" [mat-menu-trigger-for]="menu">
    <ngx-avatars [size]="size === 'large' ? 61 : 40" [src]="profilePictureUrl" [name]="fullName"></ngx-avatars>
    <span class="caret"></span>
</button>
<mat-menu #menu>
    <div class="menu-container">
        <a mat-menu-item disableRipple [href]="authAccountUrl">
            <div class="user-layout">
                <ngx-avatars size="40" [src]="profilePictureUrl" [name]="fullName"></ngx-avatars>
                <span class="name">{{ fullName }}</span>
                <span class="email">{{ emailAddress }}</span>
                <mat-icon class="launch">launch</mat-icon>
            </div>
        </a>
        <a mat-menu-item disableRipple *ngIf="isPartner" [routerLink]="['/client-admin/clients']">
            {{'manageClientsScreen.title' | translate}}
        </a>
        <ng-container *ngIf="isSuperAdmin">
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/partners']">
                {{'UserMenuTopRight.managePartners' | translate}}
            </a>
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/features']">
                {{'UserMenuTopRight.manageFeatures' | translate}}
            </a>
        </ng-container>
        <a mat-menu-item disableRipple *ngIf="isPartner" [routerLink]="['/pages/configtemplategrid']">
            {{'UserMenuTopRight.configTemplates' | translate}}
        </a>
        <ng-container *ngIf="isSuperAdmin">
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/suggestions']">
                {{'UserMenuTopRight.suggestions' | translate}}
            </a>
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/weighting']">
                {{'scoreWeightings.title' | translate}}
            </a>
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/subscription-products']">
                Subscription Products
            </a>
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/payment']">
                {{'paymentManagementScreen.title' | translate}}
            </a>
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/prompt-overrides']">
                {{'promptOverrides.title' | translate}}
            </a>
            <a mat-menu-item disableRipple [routerLink]="['/platform-admin/eventhistory']">
                {{'UserMenuTopRight.eventHistory' | translate}}
            </a>
        </ng-container>
        <ng-container *ngIf="!useNewBillingPage; else newBillingPage">
            <a mat-menu-item disableRipple *ngIf="isBillingAdmin && hasSubscription()" href="javascript:void(0)"
                (click)="billingDetails()">
                Billing Details
            </a>
        </ng-container>
        <ng-template #newBillingPage>
            <a mat-menu-item disableRipple *ngIf="isBillingAdmin" [routerLink]="['./settings/billing']">
                {{ 'billing.menuTitle' | translate }}
            </a>
        </ng-template>
        <a mat-menu-item disableRipple *ngIf="isPartnerStripeAccountAvailable$ | async" href="javascript:void(0)"
            (click)="goToPartnerStripePortal()">
            {{'UserMenuTopRight.partnerStripeDashboard' | translate}}
        </a>
        <a mat-menu-item disableRipple *ngIf="showTimeMachine" [routerLink]="['/pages/timemachine']">
            {{'UserMenuTopRight.timeMachine' | translate}}
        </a>
        <a mat-menu-item disableRipple *ngIf="demoServer && (isCloneAvailable$ | async)" href="javascript:void(0)"
            (click)="openCloneTemplateDialog()">
            {{'UserMenuTopRight.loadTemplate' | translate}}
        </a>
        <a mat-menu-item disableRipple href="javascript:void(0)" (click)="logout()">
            {{'UserMenuTopRight.logOut' | translate}}
        </a>
    </div>
</mat-menu>