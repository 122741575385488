import { trigger } from "@angular/animations";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AddDiscussionDto, CategoryDetailDto, DiscussionAndSolutionDto, DiscussionsApi, SimpleCategoryDto, SimpleCompanyTeamDto, SimpleDepartmentDto, SimpleSubCategoryDto, SimpleUserDto, TeamReferenceDto, UpdateDiscussionDto } from "@api";
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, first, map, Observable, Subscription, switchMap, tap } from "rxjs";

import { EditSolutionDialogComponent } from "~/app/meeting/dialogs";
import { CategoryRepository, DepartmentRepository, TeamRepository, UserRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { MeetingProgressService } from "~services/meeting-progress.service";
import { NotificationService } from "~services/notification.service";
import { DiscussionStateService } from "~services/state";
import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { DiscussionType, Priority } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeInAnimationBuilder } from "~shared/util/animations";
import { isCrossTeamFeaturesEnabled, isCrossTeamFeaturesTeased } from "~shared/util/feature-helper";
import { IOriginDetails } from "~shared/util/origin-builder";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getTeamSearchData } from "~shared/util/team-helper";
import { getDiscussionTypeNameKey, getPriorityNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

import { convertToDiscussionInput, IDiscussionInputModel } from "../discussion-dialog-shared";

interface IDiscussionDialogData {
    discussion?: DiscussionAndSolutionDto;
    companyId: string;
    teamId: string;
    origin?: IOriginDetails;

    readonly: boolean;
    discussionInput?: IDiscussionInputModel;
}

export interface IAddDiscussionDialogData {
    companyId: string;
    teamId: string;
    origin?: IOriginDetails;
    discussionInput?: IDiscussionInputModel;
}

export interface IEditDiscussionDialogData {
    discussion: DiscussionAndSolutionDto;
    readonly?: boolean;
}

interface CompanyTeamId {
    companyId: string;
    teamId: string;
}

const CURRENT_USER = "currentUser";

@Component({
    selector: "app-edit-discussion-dialog",
    templateUrl: "./edit-discussion-dialog.component.html",
    styleUrls: ["./edit-discussion-dialog.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
    ],
    standalone: false,
})
export class EditDiscussionDialogComponent extends WithDestroy() implements OnInit, OnDestroy {

    buttonState: ButtonState;
    resolveNowState: ButtonState;

    readonly priorities = [
        Priority.low,
        Priority.medium,
        Priority.high
    ];

    readonly types = [
        DiscussionType.discussion,
        DiscussionType.challenge,
        DiscussionType.opportunity,
    ];

    readonly headingControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.maxLength(1000)]);
    readonly creatorControl = this.fb.nonNullable.control<string | null>(CURRENT_USER, [Validators.required]);
    readonly companyControl = this.fb.nonNullable.control<string>("", [Validators.required]);
    readonly teamControl = this.fb.nonNullable.control<string>("", [Validators.required]);

    readonly typeControl = this.fb.nonNullable.control(DiscussionType.discussion, [Validators.required]);
    readonly priorityControl = this.fb.nonNullable.control(Priority.medium, [Validators.required]);
    readonly departmentControl = this.fb.control<string | null>(null);
    readonly categoryControl = this.fb.control<string | null>(null);
    readonly subCategoryControl = this.fb.control<string | null>(null);

    readonly form = new FormGroup({
        heading: this.headingControl,
        description: this.descriptionControl,
        creator: this.creatorControl,
        company: this.companyControl,
        team: this.teamControl,

        type: this.typeControl,
        priority: this.priorityControl,
        department: this.departmentControl,
        category: this.categoryControl,
        subCategory: this.subCategoryControl,
    });

    get isNewDiscussion(): boolean {
        return !this.discussion;
    }

    get originalCreator(): SimpleUserDto | undefined {
        return this.discussion?.creator;
    }

    get originalDepartment(): SimpleDepartmentDto | undefined {
        return this.discussion?.department;
    }

    get originalCategory(): SimpleCategoryDto | undefined {
        return this.discussion?.category;
    }

    get originalSubCategory(): SimpleSubCategoryDto | undefined {
        return this.discussion?.category?.subCategory;
    }

    get isTeamEditable(): boolean {
        // We can edit the team either for a new discussion, or when editing an unsolved discussion in a meeting
        return !this.discussion || (!!this.discussion && !this.discussion.solution && this.inMeeting);
    }

    readonly originalTeamName: string;

    readonly readonly: boolean;
    readonly origin?: IOriginDetails;

    readonly getUserName = getUserName;
    readonly getPriorityNameKey = getPriorityNameKey;
    readonly getTypeNameKey = getDiscussionTypeNameKey;
    readonly getTeamSearchData = getTeamSearchData;

    readonly canResolveNow$: Observable<boolean>;
    readonly crossTeamTeased$: Observable<boolean>;
    readonly teamSelectionEnabled$: Observable<boolean>;

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly users$: Observable<SimpleUserDto[]>;
    readonly departments$: Observable<SimpleDepartmentDto[]>;
    readonly categories$: Observable<CategoryDetailDto[]>;
    readonly subCategories$: Observable<SimpleSubCategoryDto[]>;

    private get creatorUserId(): string | undefined {
        const creator = this.creatorControl.value;
        if (!creator || creator === CURRENT_USER) return undefined;
        return creator;
    }

    private readonly discussion?: DiscussionAndSolutionDto;
    private readonly inMeeting: boolean;

    private readonly subscriptions = new Subscription();
    private readonly companyTeamIdSubject: BehaviorSubject<CompanyTeamId>;

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly discussionStateService: DiscussionStateService,
        private readonly teamRepository: TeamRepository,
        private readonly departmentRepository: DepartmentRepository,
        private readonly categoryRepository: CategoryRepository,
        private readonly userRepository: UserRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly meetingProgressService: MeetingProgressService,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<EditDiscussionDialogComponent, DiscussionAndSolutionDto>,
        @Inject(MAT_DIALOG_DATA) data: IDiscussionDialogData,
    ) {
        super();

        this.companyControl.setValue(data.companyId);
        this.teamControl.setValue(data.teamId);
        this.companyTeamIdSubject = new BehaviorSubject({
            companyId: data.companyId,
            teamId: data.teamId,
        });

        this.discussion = data.discussion;
        this.inMeeting = this.meetingProgressService.isInProgress(data.companyId, data.teamId);
        this.readonly = data.readonly;
        if (this.readonly) this.form.disable();
        this.origin = data.origin;
        this.updateControlState(data);
        this.originalTeamName = this.discussion?.team.name ?? this.teamContext.team()?.name ?? "";

        this.crossTeamTeased$ = this.teamContext.companyTeam$.pipe(map(isCrossTeamFeaturesTeased));
        const crossTeamEnabled$ = this.teamContext.companyTeam$.pipe(map(isCrossTeamFeaturesEnabled));
        this.teamSelectionEnabled$ = crossTeamEnabled$.pipe(
            map(s => s && this.isTeamEditable));

        this.teams$ = this.teamRepository.getClientInstanceTeams(data.companyId);
        this.users$ = this.companyTeamIdSubject.pipe(
            switchMap(({ companyId, teamId }) => this.userRepository.getTeamMembers(companyId, teamId)),
            map(this.addCurrentUser),
            tap(this.availableUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        const companyId$ = this.companyTeamIdSubject.pipe(
            map(({ companyId }) => companyId),
            distinctUntilChanged(),
        );
        this.departments$ = companyId$.pipe(
            switchMap(companyId => this.departmentRepository.getDepartments(companyId)),
            tap(this.availableDepartmentsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.categories$ = companyId$.pipe(
            switchMap(companyId => this.categoryRepository.getCategories(companyId)),
            tap(this.availableCategoriesChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.subCategories$ = combineLatest({
            categories: this.categories$,
            categoryId: valueAndChanges(this.categoryControl),
        }).pipe(
            map(({ categories, categoryId }) => categories.find(c => c.id === categoryId)?.subCategories ?? []),
            tap(this.availableSubCategoriesChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.canResolveNow$ = this.companyTeamIdSubject.pipe(
            switchMap(({ companyId, teamId }) => this.meetingProgressService.isInProgress$(companyId, teamId)),
            shareReplayUntil(this.destroyed$),
        );
    }

    static openForAdd(dialog: MatDialog, data: IAddDiscussionDialogData) {
        return this.openInternal(dialog, {
            discussion: undefined,
            companyId: data.companyId,
            teamId: data.teamId,
            origin: data.origin,
            readonly: false,
            discussionInput: data.discussionInput && {
                ...data.discussionInput,
                creatorUserId: data.discussionInput.creatorUserId ?? CURRENT_USER,
            },
        });
    }

    static openForEdit(dialog: MatDialog, data: IEditDiscussionDialogData) {
        const discussion = data.discussion;
        const origin = discussion.origin;
        return this.openInternal(dialog, {
            discussion: discussion,
            companyId: discussion.company.id,
            teamId: discussion.team.id,
            origin: origin && {
                companyId: origin.company.id,
                teamId: origin.team.id,
                type: origin.type,
                id: origin.id,
                week: origin.week,
                heading: origin.heading,
                description: origin.description
            },
            readonly: data.readonly ?? false,
            discussionInput: convertToDiscussionInput(discussion),
        });
    }

    private static openInternal(dialog: MatDialog, data: IDiscussionDialogData) {
        return dialog.open<EditDiscussionDialogComponent, IDiscussionDialogData, DiscussionAndSolutionDto>(EditDiscussionDialogComponent, {
            width: "750px",
            data: data
        });
    }

    ngOnInit(): void {
        if (this.readonly) return;
        this.subscriptions.add(
            valueAndChanges(this.teamControl).pipe(
                switchMap(teamId => this.teams$.pipe(
                    first(),
                    map(teams => teams.find(t => t.id === teamId)),
                )),
                filter(Boolean),
                map(team => ({ companyId: team.company.id, teamId: team.id })),
                filter(({ companyId, teamId }) => {
                    if (!companyId || !teamId) return false;
                    const current = this.companyTeamIdSubject.value;
                    return current.companyId !== companyId || current.teamId !== teamId;
                }),
                tap(({ companyId }) => {
                    this.companyControl.setValue(companyId);
                }),
            ).subscribe(this.companyTeamIdSubject),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    save = (resolveNow = false) => {
        if (this.buttonState || this.resolveNowState) return;
        if (resolveNow) this.form.markAllAsTouched();
        if (!this.form.valid) return;

        this.setButtonState(resolveNow, "loading");
        let result$: Observable<DiscussionAndSolutionDto>;
        if (this.discussion) {
            result$ = this.discussionsApi.updateDiscussion(
                this.discussion.company.id,
                this.discussion.team.id,
                this.discussion.id,
                this.getUpdateDiscussionDto(this.discussion),
            );
        } else {
            result$ = this.discussionsApi.addDiscussion(
                this.companyControl.value,
                this.teamControl.value,
                this.getAddDiscussionDto(),
            );
        }
        result$.pipe(wrapWorkfactaError()).subscribe({
            next: result => {
                this.setButtonState(resolveNow, "success");
                if (this.discussion) {
                    this.discussionStateService.notifyUpdate(result);
                } else {
                    this.discussionStateService.notifyAdd(result);
                }
                if (resolveNow && this.meetingProgressService.isInProgress(result.company.id, result.team.id)) {
                    EditSolutionDialogComponent.open(this.dialog, result, true);
                    this.dialogRef.close(result);
                } else {
                    setTimeout(() => this.dialogRef.close(result), 1000);
                }
            },
            error: error => {
                this.setButtonState(resolveNow, "error");
                setTimeout(() => {
                    this.setButtonState(resolveNow, undefined);
                }, 2000);
                if (error instanceof WorkfactaError) {
                    switch (error.status) {
                        case 409:
                            switch (error.code) {
                                case ErrorCode.planCapReached:
                                    this.form.setErrors({ capReached: true });
                                    return;
                            }
                    }
                }
                this.notificationService.errorUnexpected();
            }
        });
    };

    getUserId = (user: SimpleUserDto) => user.userId;

    getTeamId = (team: SimpleCompanyTeamDto) => team.id;
    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined) => team?.name ?? "";

    getDepartmentId = (department: SimpleDepartmentDto) => department.id;
    getDepartmentName = (department: SimpleDepartmentDto | null | undefined) => department?.name ?? "";
    getCategoryId = (category: CategoryDetailDto): string => category?.id ?? "";
    getCategoryDisplay = (category: CategoryDetailDto | null | undefined) => category?.description ?? "";
    getSubCategoryId = (subCategory: SimpleSubCategoryDto): string => subCategory?.id ?? "";
    getSubCategoryDisplay = (subCategory: SimpleSubCategoryDto | null | undefined): string => subCategory?.description ?? "";

    private getUpdateDiscussionDto = (discussion: DiscussionAndSolutionDto): UpdateDiscussionDto => {
        const teamId: string = this.teamControl.value;
        const companyId: string = this.companyControl.value;
        let transferToTeam: TeamReferenceDto | undefined;
        if (teamId !== discussion.team.id || companyId !== discussion.company.id) {
            transferToTeam = {
                companyId: companyId,
                teamId: teamId,
            };
        }
        return {
            ...this.getCoreDiscussionDto(),
            transferToTeam: transferToTeam,
        };
    };

    private getAddDiscussionDto = (): AddDiscussionDto => ({
        ...this.getCoreDiscussionDto(),
        type: this.typeControl.value,
        creatorUserId: this.creatorUserId,
        origin: this.origin && {
            companyId: this.origin.companyId,
            teamId: this.origin.teamId,
            type: this.origin.type,
            id: this.origin.id,
            week: this.origin.week,
        },
    });

    private getCoreDiscussionDto = () => ({
        heading: this.headingControl.value ?? "",
        description: this.descriptionControl.value ?? undefined,
        priority: this.priorityControl.value,
        departmentId: this.departmentControl.value ?? undefined,
        categoryId: this.categoryControl.value ?? undefined,
        subCategoryId: this.subCategoryControl.value ?? undefined,
    });

    private updateControlState = (data: IDiscussionDialogData) => {
        if (data.discussionInput) {
            this.bindDiscussion(data.discussionInput);
        }

        if (data.discussion) {
            // We are updating a discussion.
            const discussion = data.discussion;

            // The type or creator of a discussion cannot be changed after creation.
            this.typeControl.disable();
            this.creatorControl.disable();

            // We can only change the team when not solved and in a meeting
            if (discussion.solution || !this.inMeeting) {
                this.teamControl.disable();
            }
        }
    };

    private bindDiscussion = (d: IDiscussionInputModel) => {
        this.headingControl.setValue(d.heading ?? null);
        this.descriptionControl.setValue(d.description ?? null);
        this.creatorControl.setValue(d.creatorUserId ?? null);
        this.typeControl.setValue(d.type ?? DiscussionType.discussion);
        this.priorityControl.setValue(d.priority ?? Priority.medium);
        this.departmentControl.setValue(d.departmentId ?? null);
        this.categoryControl.setValue(d.categoryId ?? null);
        this.subCategoryControl.setValue(d.subCategoryId ?? null);
    };

    private addCurrentUser = (users: SimpleUserDto[]): SimpleUserDto[] => {
        const user = this.userContext.user();
        if (!user) return users;
        const currentUser: SimpleUserDto = {
            userId: CURRENT_USER,
            firstName: user.firstName,
            lastName: user.lastName,
        };
        users = [...users];
        const index = users.findIndex(u => u.userId === user.id);
        if (index >= 0) {
            // Replace the user with the dummy record.
            users.splice(index, 1, currentUser);
        } else {
            // Add the current user to the start of the list.
            users.unshift(currentUser);
        }
        return users;
    };

    private availableUsersChanged = (users: SimpleUserDto[]) => {
        if (!users.some(x => x.userId === this.creatorControl.value)) {
            this.creatorControl.setValue(null);
        }
        if (this.isNewDiscussion && !this.creatorControl.value) {
            this.creatorControl.setValue(CURRENT_USER);
        }
    };

    private availableDepartmentsChanged = (departments: SimpleDepartmentDto[]) => {
        if (this.departmentControl.value && !departments.some(d => d.id === this.departmentControl.value)) {
            this.departmentControl.setValue(null);
        }
    };

    private availableCategoriesChanged = (categories: CategoryDetailDto[]) => {
        if (!categories.length) {
            // Ensures even if the list of sub-categories is not subscribed to (due to no categories)
            // the control will be cleared and disabled appropriately.
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
        }
        if (this.categoryControl.value && !categories.some(x => x.id === this.categoryControl.value)) {
            this.categoryControl.setValue(null);
        }
    };

    private availableSubCategoriesChanged = (subCategories: SimpleSubCategoryDto[]) => {
        if (!subCategories.length) {
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
            return;
        }

        if (this.form.enabled) this.subCategoryControl.enable();
        if (this.subCategoryControl.value && !subCategories.some(x => x.id === this.subCategoryControl.value)) {
            this.subCategoryControl.setValue(null);
        }
    };

    private setButtonState = (resolveNow: boolean, state: ButtonState) => {
        if (resolveNow) {
            this.resolveNowState = state;
        } else {
            this.buttonState = state;
        }
    };
}
