import { Injectable } from "@angular/core";
import { PeriodApi } from "@api";
import { PeriodGroupDto } from "@api/model/periodGroupDto";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { concatMap, map, shareReplay } from "rxjs/operators";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";

import { IClearableRepository } from "./repository";

export interface IWeekModel {
    financialYear: number;
    planningPeriodIndex: number;
    collectionPeriodIndex: number;

    fiscalYear: string;
    qtr: string;
    week: number;
}

interface IWeekCache {
    [dayOffset: number]: ICompanyCache<IWeekModel>;
}

@Injectable({
    providedIn: "root"
})
export class WeekRepository implements IClearableRepository {

    private weekCache: IWeekCache = {};

    constructor(private readonly periodApi: PeriodApi) { }

    clearCache = () => {
        this.weekCache = {};
    };

    getCurrentWeekData = (companyId: string): Observable<IWeekModel> => this.getWeekData(companyId, 0);

    getNextWeekData = (companyId: string): Observable<IWeekModel> => this.getWeekData(companyId, 7);

    getWeekData = (companyId: string, dayOffset: number): Observable<IWeekModel> => {
        const cache = this.getCompanyCache(dayOffset);
        let cacheItem = getCompanyCacheItem(cache, companyId);
        if (!cacheItem) {
            cacheItem = this.getObservableForDayOffset(companyId, dayOffset);
            setCompanyCacheItem(cache, companyId, cacheItem);
        }
        return cacheItem;
    };

    private getCompanyCache = (dayOffset: number): ICompanyCache<IWeekModel> => {
        let cache = this.weekCache[dayOffset];
        if (!cache) {
            cache = {};
            this.weekCache[dayOffset] = cache;
        }
        return cache;
    };

    private getObservableForDayOffset = (companyId: string, dayOffset: number): Observable<IWeekModel> => of(null).pipe(
        concatMap(() => {
            const obs =
                dayOffset === 0 ?
                    this.periodApi.getCurrentPeriodGroup(companyId) :
                    this.periodApi.getPeriodGroupByDate(companyId, moment().add(dayOffset, "days").toISOString());
            return obs.pipe(
                retryWithDelay(),
                map(response => this.mapWeekResponse(response))
            );
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
    );

    private mapWeekResponse = (response: PeriodGroupDto): IWeekModel => ({
        financialYear: response.current.financialYear,
        planningPeriodIndex: response.current.planningPeriodIndex,
        collectionPeriodIndex: response.current.collectionPeriodIndex,

        fiscalYear: response.current.fiscalYear,
        qtr: response.current.qtr,
        week: response.current.week,
    });
}
