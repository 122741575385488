import { Injectable } from "@angular/core";
import { PeriodApi, PlanningPeriodDetailsDto } from "@api";
import { PeriodGroupDto } from "@api/model/periodGroupDto";
import { Observable, of } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";

import { IClearableRepository } from "./repository";

interface IPeriodCache {
    [financialYear: number]: ICompanyCache<PlanningPeriodDetailsDto[]>;
}

const PERIOD_CACHE_EXPIRY_MS = 60 * 60 * 1000; // 1 hour - we keep for longer as this should rarely if ever change

@Injectable({
    providedIn: "root"
})
export class PeriodRepository implements IClearableRepository {

    private currentPeriodCache: ICompanyCache<PeriodGroupDto> = {};
    private periodCache: IPeriodCache = {};

    constructor(private readonly periodApi: PeriodApi) { }

    clearCache = () => {
        this.currentPeriodCache = {};
        this.periodCache = {};
    };

    getCurrentPeriod = (companyId: string): Observable<PeriodGroupDto> => {
        let cacheItem = getCompanyCacheItem(this.currentPeriodCache, companyId);
        if (!cacheItem) {
            cacheItem = this.getCurrentPeriodObservable(companyId);
            setCompanyCacheItem(this.currentPeriodCache, companyId, cacheItem);
        }
        return cacheItem;
    };

    getPeriods = (companyId: string, financialYear: number): Observable<PlanningPeriodDetailsDto[]> => {
        const cache = this.getCompanyPeriodCache(financialYear);
        let cacheItem = getCompanyCacheItem(cache, companyId);
        if (!cacheItem) {
            cacheItem = this.getPeriodObservable(companyId, financialYear);
            setCompanyCacheItem(cache, companyId, cacheItem, PERIOD_CACHE_EXPIRY_MS);
        }
        return cacheItem;
    };

    private getCompanyPeriodCache = (financialYear: number): ICompanyCache<PlanningPeriodDetailsDto[]> => {
        let cache = this.periodCache[financialYear];
        if (!cache) {
            cache = {};
            this.periodCache[financialYear] = cache;
        }
        return cache;
    };

    private getCurrentPeriodObservable = (companyId: string) =>
        this.periodApi.getCurrentPeriodGroup(companyId).pipe(
            retryWithDelay(),
            tap(period => {
                const year = period.group[0].financialYear;
                const periodCache = this.getCompanyPeriodCache(year);
                setCompanyCacheItem(periodCache, companyId, of(period.group), PERIOD_CACHE_EXPIRY_MS);
            }),
            shareReplay({ bufferSize: 1, refCount: false }),
        );

    private getPeriodObservable = (companyId: string, financialYear: number) =>
        this.periodApi.getPeriodGroupByYear(companyId, financialYear).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}
