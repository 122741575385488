import { Component, computed, HostBinding, input } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { GetNumberDto, NumberRecordDetailDto } from "@api";
import { Observable, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { ViewNumberCalcSourcesDialogComponent } from "~shared/dialogs";
import { CaptureMethod } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { getRelevantWeek } from "~shared/util/number-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

@Component({
    selector: "app-number-calc-sources-button",
    templateUrl: "./number-calc-sources-button.component.html",
    styleUrls: ["./number-calc-sources-button.component.scss"],
    standalone: false,
})
export class NumberCalcSourcesButtonComponent extends WithDestroy() {

    readonly number = input.required<GetNumberDto | NumberRecordDetailDto>();

    readonly isCalculated = computed(() => this.captureMethod() === CaptureMethod.calculated);
    readonly isDeployed = computed(() => this.captureMethod() === CaptureMethod.deployed);
    readonly isDailyUpdated = computed(() => !!this.number().dailyUpdateDefinition);

    readonly canViewDetails = computed(() => this.relevantWeek() != null);

    readonly isIncomplete = computed(() => {
        const number = this.number();
        if ("week" in number) return number.incomplete ?? false;
        const relevantWeek = this.relevantWeek();
        const record = number.records.find(r => r.week === relevantWeek);
        return record?.incomplete ?? false;
    });

    @HostBinding("class.hidden")
    get isHidden(): boolean {
        return !this.isCalculated && !this.isDeployed && !this.isDailyUpdated;
    }

    readonly tooltipKey = computed(() => {
        if (this.isDeployed()) {
            return "numbers.deployment.viewBreakdown";
        }
        if (this.isDailyUpdated()) {
            return "numbers.dailyUpdates.viewBreakdown";
        }
        return "numbers.calculation.viewBreakdown";
    });

    readonly iconName = computed(() => {
        if (this.isDeployed()) {
            return "device_hub";
        } else if (this.isCalculated()) {
            return "calculate";
        } else {
            return "date_range";
        }
    });

    readonly hasAccess$: Observable<boolean>;

    private readonly relevantWeek = computed(() => getRelevantWeek(this.number()));
    private readonly captureMethod = computed(() => this.number().captureMethod);

    constructor(
        private readonly accessService: AccessService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.hasAccess$ = toObservable(this.number).pipe(
            switchMap(number => {
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.accessService.canAccessCompanyTeam(company.id, team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    openDialog = () => {
        const number = this.number();
        const week = this.relevantWeek();
        if (!number || week == null) return;

        ViewNumberCalcSourcesDialogComponent.open(this.dialog, number, week);
    };

}
