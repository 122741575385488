import { NotificationTimeFrame } from "@api";

export const getNotificationTimeFrameNameKey = (timeFrame: NotificationTimeFrame): string => {
    switch (timeFrame) {
        case NotificationTimeFrame.week:
            return "setupNotificationScreen.wholeWeek";
        case NotificationTimeFrame.today:
            return "setupNotificationScreen.currentDay";
        case NotificationTimeFrame.yesterday:
            return "setupNotificationScreen.previousDay";
    }
};
