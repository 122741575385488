import { Injectable } from "@angular/core";
import { GetGoalDto, PlanGoalsApi } from "@api";
import { Observable, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

import { toFiscalQuarter } from "~shared/commonfunctions";
import { getTeamCacheItem, ICompanyTeamCache, retryWithDelay, setTeamCacheItem } from "~shared/util/caching";

import { IClearableRepository } from "./repository";
import { WeekRepository } from "./week.repository";

@Injectable({
    providedIn: "root"
})
export class CurrentGoalsRepository implements IClearableRepository {

    private cache: ICompanyTeamCache<GetGoalDto[]> = {};

    constructor(private readonly weekRepository: WeekRepository, private readonly planGoalsApi: PlanGoalsApi) { }

    clearCache = () => {
        this.cache = {};
    };

    getCurrentGoals = (companyId: string, teamId: string, force = false): Observable<GetGoalDto[]> => {
        let cacheItem = force ? null : getTeamCacheItem(this.cache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getCurrentGoalsObservableForCompanyTeam(companyId, teamId);
            setTeamCacheItem(this.cache, companyId, teamId, cacheItem);
        }
        return cacheItem;
    };

    private getCurrentGoalsObservableForCompanyTeam = (companyId: string, teamId: string) =>
        this.weekRepository.getCurrentWeekData(companyId).pipe(
            switchMap(week => !week ? of([]) : this.planGoalsApi.listGoals(
                companyId,
                teamId,
                toFiscalQuarter({ financialYear: week.financialYear, quarter: week.planningPeriodIndex }),
            ).pipe(map(response => response.goals))),
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}
