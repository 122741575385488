<app-homepage-scaffold *ngIf="action">
    <span *appHomepageTitle class="multiline-description">{{ action.description }}</span>

    <ng-template appHomepageActions>
        <app-watch-item-button type="action" [source]="action" [isPrivate]="action.isPrivateAction"></app-watch-item-button>
        <app-add-action-child-action-button [source]="action"></app-add-action-child-action-button>
        <app-add-action-child-discussion-button [source]="action"></app-add-action-child-discussion-button>
        <app-edit-action-menu [action]="action" (updated)="afterUpdated($event)" (deleted)="afterDeleted()"></app-edit-action-menu>
    </ng-template>

    <app-origin-header *ngIf="action.origin" [origin]="action.origin"></app-origin-header>

    <section class="wf-section">
        <dl>
            <div>
                <dt translate="actions.owner"></dt>
                <dd>
                    <app-delegated-user-display [entity]="action" primary>
                        {{ getUserName(action.owner) }}
                        <wf-private-indicator *ngIf="action.isPrivateAction"></wf-private-indicator>
                    </app-delegated-user-display>
                </dd>
            </div>
            <div>
                <dt translate="actions.dueDate"></dt>
                <dd>{{ action.dueDateLocal | dateFormat }}</dd>
            </div>
            <div>
                <dt translate="actions.team"></dt>
                <dd>{{ action.team.name }} ({{ action.company.name }})</dd>
            </div>
            <div>
                <dt translate="actions.priority"></dt>
                <dd>
                    <app-priority [priority]="action.priority"></app-priority>
                </dd>
            </div>
            <div>
                <dt translate="actions.creator"></dt>
                <dd>
                    {{ getUserName(action.creator) }}
                </dd>
            </div>
            <div>
                <dt translate="actions.created"></dt>
                <dd>{{ action.createdDateLocal | dateFormat }}</dd>
            </div>
            <div *ngIf="action.department">
                <dt translate="actions.department"></dt>
                <dd>{{ action.department.name }}</dd>
            </div>
            <div *ngIf="action.category" class="sub-list">
                <div>
                    <dt translate="actions.category"></dt>
                    <dd>{{ action.category.description }}</dd>
                </div>
                <div *ngIf="action.category.subCategory">
                    <dt translate="actions.subCategory"></dt>
                    <dd>{{ action.category.subCategory.description }}</dd>
                </div>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'actions.status' | translate" expanded disabled>
        <dl>
            <div>
                <dt translate="actions.status"></dt>
                <dd>
                    <app-action-progress-update [action]="action"
                        (updated)="afterUpdated($event)"></app-action-progress-update>
                </dd>
            </div>
            <div *ngIf="action.status">
                <dt translate="actions.completedDate"></dt>
                <dd>{{ action.solvedDateLocal | dateFormat }}</dd>
            </div>
        </dl>
    </section>

    <ng-container *ngIf="dueDateHistory$ | async; let history">
        <section wf-expandable-section [label]="'actions.dueDateHistory' | translate" *ngIf="history.length > 1" @fadeIn>
            <div class="table-container">
                <table mat-table class="wf-table" [dataSource]="history" wfTableFocus>
                    <ng-container matColumnDef="updated">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'actions.updated' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let change">
                            {{ change.updatedDateLocal | dateTimeFormat }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dueDate">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'actions.dueDate' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let change">
                            {{ change.dueDateLocal | dateFormat }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
                    <tr mat-row *matRowDef="let row; let i = index; columns: historyColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i">
                </table>
            </div>
        </section>
    </ng-container>

    <ng-container *ngIf="relatedActions$ | async; let actions">
        <section wf-expandable-section [label]="'homepage.relatedActions' | translate" *ngIf="actions.length" @fadeIn>
            <app-related-actions-table *wfExpandableSectionBody [actions]="actions"></app-related-actions-table>
        </section>
    </ng-container>

    <ng-container *ngIf="relatedDiscussions$ | async; let discussions">
        <section wf-expandable-section [label]="'homepage.relatedDiscussions' | translate" *ngIf="discussions.length" @fadeIn>
            <app-related-discussions-table *wfExpandableSectionBody [discussions]="discussions"></app-related-discussions-table>
        </section>
    </ng-container>

</app-homepage-scaffold>