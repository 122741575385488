<app-homepage-scaffold *ngIf="number">
    <span *appHomepageTitle>
        <span class="multiline-description">{{ number.description }}</span>
        @if (number.updateDay != null) {
            {{ ' ' }}
            <span class="update-day">
                ({{ getDayOfWeekNameKey(number.updateDay!) | translate }})
            </span>
        }
    </span>

    <ng-template appHomepageTitleActions>
        <app-schedule-navigator [item]="number" [schedule]="schedule$ | async" (selected)="selectSchedule($event)"></app-schedule-navigator>
    </ng-template>

    <ng-template appHomepageActions>
        <app-watch-item-button type="number" [source]="number" [isPrivate]="number.isPrivate"></app-watch-item-button>
        <app-add-number-child-action-button [source]="number"></app-add-number-child-action-button>
        <app-add-number-child-discussion-button [source]="number"></app-add-number-child-discussion-button>
        <ng-container *ngIf="allowEdit">
            <button mat-icon-button type="button" [matMenuTriggerFor]="popupMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #popupMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="edit()" *ngIf="(access$ | async)?.canEdit; else viewButton">
                    <mat-icon color="primary">edit</mat-icon>
                    <span>{{'Edit' | translate}}</span>
                </button>
                <ng-template #viewButton>
                    <button mat-menu-item (click)="view()">
                        <mat-icon color="primary">visibility</mat-icon>
                        <span>{{'View' | translate}}</span>
                    </button>
                </ng-template>
                <button mat-menu-item *ngIf="canMigrate()" (click)="migrateNumber()">
                    <mat-icon color="accent">device_hub</mat-icon>
                    <span>{{'numbers.deployment.migrateToDeployment' | translate}}</span>
                </button>
                <button mat-menu-item *ngIf="canAdopt()" (click)="adoptNumber()">
                    <mat-icon color="accent">crib</mat-icon>
                    <span>{{'numbers.deployment.adoptNumber' | translate}}</span>
                </button>
                <button mat-menu-item *ngIf="canCopyBackwards()" (click)="copyBackwards()">
                    <mat-icon color="accent">history</mat-icon>
                    <span>{{'numbers.copyBackwards.title' | translate}}</span>
                </button>
                <button mat-menu-item (click)="delete()" [disabled]="!(access$ | async)?.canDelete">
                    <mat-icon color="warn">delete</mat-icon>
                    <span>{{'Delete' | translate}}</span>
                </button>
            </mat-menu>
        </ng-container>
    </ng-template>

    @if (number.source; as source) {
        @if (isDeployedNumber(number) && deployedNumbersEnabled() || isDailyNumber(number) && dailyUpdatedNumbersEnabled()) {
            <app-number-source-header [source]="source" [type]="isDeployedNumber(number) ? 'deployed' : 'daily'"></app-number-source-header>
        }
    }
    
    <section class="wf-section">
        <dl>
            <div>
                <dt translate="numbers.resultToDate"></dt>
                <dd>
                    <app-number-result [result]="number.resultToDate" [target]="number.targetToDate"
                        [numberType]="number.type"></app-number-result>
                </dd>
            </div>
            <div *ngIf="isTargetSet(number.targetToDate)">
                <dt translate="numbers.targetToDate"></dt>
                <dd>
                    <app-number-target [target]="number.targetToDate" [numberType]="number.type"></app-number-target>
                </dd>
            </div>
            <div>
                <dt translate="numbers.owner"></dt>
                <dd>
                    <app-delegated-user-display [entity]="number" primary>
                        {{ getUserName(number.owner) }}
                        <wf-private-indicator *ngIf="number.isPrivate"></wf-private-indicator>
                    </app-delegated-user-display>
                </dd>
            </div>
            <div *ngIf="allowsUpdater(number)">
                <dt translate="numbers.updater"></dt>
                <dd>
                    <app-delegated-user-display [entity]="number" mode="updater" primary>
                        {{ getUserName(number.updater) }}
                    </app-delegated-user-display>
                </dd>
            </div>
            <div>
                <dt translate="numbers.team"></dt>
                <dd>{{ number.team.name }} ({{ number.company.name }})</dd>
            </div>
            <div *ngIf="number.department">
                <dt translate="numbers.department"></dt>
                <dd>{{ number.department.name }}</dd>
            </div>
            <div *ngIf="number.category" class="sub-list">
                <div>
                    <dt translate="numbers.category"></dt>
                    <dd>{{ number.category.description }}</dd>
                </div>
                <div *ngIf="number.category.subCategory">
                    <dt translate="numbers.subCategory"></dt>
                    <dd>{{ number.category.subCategory.description }}</dd>
                </div>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'homepage.details' | translate">
        <dl>
            <div>
                <dt translate="numbers.planningStatus"></dt>
                <dd>
                    <wf-planning-status [status]="number.numberStatus"></wf-planning-status>
                </dd>
            </div>
            <div>
                <dt translate="numbers.numberType"></dt>
                <dd [translate]="getTypeNameKey(number.type)"></dd>
            </div>
            <div>
                <dt translate="numbers.desiredResult"></dt>
                <dd [translate]="getTargetTypeNameKey(number.targetType)"></dd>
            </div>
            <div>
                <dt translate="numbers.captureFrequency"></dt>
                <dd>{{ getUpdateScheduleDescription(number) }}</dd>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'numbers.workInstruction' | translate" *ngIf="number.workInstruction && workInstructionEnabled()">
        <div *wfExpandableSectionHeader class="work-instruction-heading">
            <h4 class="wf-section-heading">{{ 'numbers.workInstruction' | translate }}</h4>
            <wf-help-indicator [matTooltip]="'numbers.workInstructionTooltip' | translate"></wf-help-indicator>
        </div>
        @if (number.workInstructionLink; as link) {
            @if (shouldShowWorkInstructionVideo(link)) {
                <app-video-player [src]="link">
                </app-video-player>
                
                <p class="work-instruction multiline-description">{{ number.workInstruction }}</p>
            } @else {
                <a [attr.href]="link" target="_blank">
                    <p class="work-instruction multiline-description">{{ number.workInstruction }}</p>
                </a>
            }
        } @else {
            <p class="work-instruction multiline-description">{{ number.workInstruction }}</p>
        }
    </section>

    <section wf-expandable-section [label]="'numbers.trend' | translate" expanded>
        <app-number-chart *wfExpandableSectionBody [number]="number" [maintainAspectRatio]="false"></app-number-chart>
    </section>

    <section wf-expandable-section [label]="'numbers.dailyUpdates.dailyBreakdown' | translate" *ngIf="dailyUpdatedNumbersEnabled() && (dailyChildren$ | async); let children">
        <app-daily-numbers-table *wfExpandableSectionBody class="table-container" [numbers]="children"></app-daily-numbers-table>
    </section>

    <section wf-expandable-section [label]="'numbers.deployment.deploymentBreakdown' | translate" *ngIf="deployedNumbersEnabled() && (deployedChildren$ | async); let children">
        <app-deployed-numbers-table *wfExpandableSectionBody class="table-container" [numbers]="children"></app-deployed-numbers-table>
    </section>

    <section wf-expandable-section [label]="'numbers.results' | translate">
        <ng-template wfExpandableSectionActions>
            <app-number-result-summary [number]="number"></app-number-result-summary>
        </ng-template>
        <app-number-records-table class="table-container" [records]="records$ | async" (recordUpdated)="recordUpdated($event)"
            [selectedWeek]="isRecord(number) ? number.week : null">
        </app-number-records-table>
    </section>

    <ng-container *ngIf="relatedActions$ | async; let actions">
        <section wf-expandable-section [label]="'homepage.relatedActions' | translate" *ngIf="actions.length" @fadeIn>
            <app-related-actions-table *wfExpandableSectionBody [actions]="actions"></app-related-actions-table>
        </section>
    </ng-container>

    <ng-container *ngIf="relatedDiscussions$ | async; let discussions">
        <section wf-expandable-section [label]="'homepage.relatedDiscussions' | translate" *ngIf="discussions.length" @fadeIn>
            <app-related-discussions-table *wfExpandableSectionBody [discussions]="discussions"></app-related-discussions-table>
        </section>
    </ng-container>

</app-homepage-scaffold>